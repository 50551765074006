@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base Styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif; /* iOS-like font stack */
  background-color: #fafafa; /* Comfortable white background, similar to iOS */
  color: #2c2c2c; /* Muted dark gray for text, less harsh */
  transition: background-color 0.3s ease, color 0.3s ease;
  line-height: 1.6; /* Comfortable line height for readability */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

input,
button,
select {
  font-family: inherit;
}

/* Dark Mode */
.dark {
  background-color: #121212;
  color: #ffffff;
}

/* Message List */
.message-list {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: #ffffff; /* Pure white for message list */
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Softer shadow */
}

.dark .message-list {
  background-color: #1e1e1e;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}
.message-list::-webkit-scrollbar {
  width: 8px;
}

.message-list::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 4px;
}

.dark .message-list::-webkit-scrollbar-thumb {
  background-color: #444444;
}

.message-list::-webkit-scrollbar-track {
  background: transparent;
}

/* Message Bubble */
/* Message Bubble */
.message-bubble {
  display: flex;
  margin-bottom: 12px;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transition */
}

.message-bubble.user {
  justify-content: flex-end;
}

.message-bubble.assistant {
  justify-content: flex-start;
}

.message-content {
  max-width: 80%;
  padding: 10px 14px; /* Adjusted padding for iMessage-like feel */
  border-radius: 50px; /* More rounded corners */
  font-size: 15px;
  line-height: 1.4;
  white-space: pre-wrap;
  word-wrap: break-word;
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth color transition */
}

/* User Message */
.message-bubble.user .message-content {
  background: linear-gradient(
    135deg,
    #0a84ff 0%,
    #0066cc 10%
  ); /* iOS-style gradient blue */
  color: #ffffff;
  border-bottom-right-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transform-origin: right bottom; /* For better animation feel */
}

.dark .message-bubble.user .message-content {
  background: linear-gradient(
    135deg,
    #0a84ff 0%,
    #0052cc 60%
  ); /* Slightly adjusted for dark mode */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* Assistant Message */
.message-bubble.assistant .message-content {
  background-color: #e5e5ea; /* Soft gray for assistant messages */
  color: #000000; /* Black text */
  border-bottom-left-radius: 4px; /* Slight tail for iMessage-like effect */
}

/* Dark Mode */
.dark .message-bubble.assistant .message-content {
  background-color: #2c2c2c; /* Dark gray for assistant messages */
  color: #ffffff; /* White text */
}

/* Smooth Appearance Animation */
.message-bubble.user .message-content,
.message-bubble.assistant .message-content {
  opacity: 0;
  transform: scale(0.8);
  animation: fadeIn 0.3s ease forwards; /* Fade-in animation */
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.message-timestamp {
  font-size: 12px;
  color: #888888;
  margin-top: 4px;
  text-align: right;
}

.dark .message-timestamp {
  color: #aaaaaa;
}

/* Thinking Indicator */
.thinking-indicator {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.thinking-indicator .dot {
  width: 8px;
  height: 8px;
  background-color: #888888;
  border-radius: 50%;
  margin: 0 4px;
  animation: bounce 1.4s infinite ease-in-out both;
}

.dark .thinking-indicator .dot {
  background-color: #aaaaaa;
}

.thinking-indicator .dot:nth-child(1) {
  animation-delay: -0.32s;
}

.thinking-indicator .dot:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* Input Area */
.input-area {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #ffffff;
  border-top: 1px solid #e0e0e0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.05);
}

.dark .input-area {
  background-color: #1e1e1e;
  border-top-color: #3a3a3a;
}

.input-field {
  flex-grow: 1;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 24px;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.input-field:focus {
  border-color: #1e90ff;
}

.dark .input-field {
  background-color: #3a3a3a;
  color: #ffffff;
  border-color: #444444;
}

.send-button {
  margin-left: 12px;
  padding: 12px;
  background-color: #1e90ff;
  color: #ffffff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.send-button:hover {
  background-color: #187bcd;
  transform: scale(1.1);
}

.send-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.dark .send-button {
  background-color: #1e90ff;
}

.dark .send-button:hover {
  background-color: #187bcd;
}

.dark .send-button:disabled {
  background-color: #444444;
}

/* Upload Button */
.upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: #e0e0e0; /* Light gray background */
  color: #212121; /* Dark text color */
  border: none;
  border-radius: 50%; /* Rounded like the send button */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.upload-button:hover {
  background-color: #cccccc; /* Slightly darker on hover */
  transform: scale(1.1); /* Slight scale-up effect on hover */
}

.dark .upload-button {
  background-color: #444444; /* Dark mode background */
  color: #ffffff; /* Light text color in dark mode */
}

.dark .upload-button:hover {
  background-color: #666666; /* Darker on hover in dark mode */
}

/* Scroll to Bottom */
.scroll-to-bottom {
  scroll-behavior: smooth;
}
