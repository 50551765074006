/* Global styles */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  background-color: #f1f3f6; /* Light gray background, macOS-like */
  color: #333; /* Neutral dark gray text */
}

.text-center {
  text-align: center;
}

/* Add text styles */
/* .font-sf-pro {
  font-family: -apple-system, "SF Pro Text", "SF Pro Icons", system-ui,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "kern";
  text-rendering: optimizeLegibility;
} */

@keyframes iosCircleDraw {
  0% {
    stroke-dasharray: 157;
    stroke-dashoffset: 157;
  }
  100% {
    stroke-dasharray: 157;
    stroke-dashoffset: 0;
  }
}

@keyframes iosCheckDraw {
  0% {
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
  }
  100% {
    stroke-dasharray: 48;
    stroke-dashoffset: 0;
  }
}

@keyframes iosFadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes iosScaleIn {
  0% {
    opacity: 0;
    transform: scale(0.95);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate-spin-slow {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-ios-circle-draw {
  animation: iosCircleDraw 0.8s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.animate-ios-check-draw {
  animation: iosCheckDraw 0.6s cubic-bezier(0.23, 1, 0.32, 1) 0.8s forwards;
}

.animate-ios-fade-in {
  animation: iosFadeIn 0.4s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.animate-ios-fade-in-delayed {
  animation: iosFadeIn 0.4s cubic-bezier(0.23, 1, 0.32, 1) 1.4s forwards;
  opacity: 0;
}

.animate-ios-scale-in {
  animation: iosScaleIn 0.5s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.ease-ios {
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.duration-800 {
  transition-duration: 800ms;
}
